import { Component, OnInit } from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt"; 

import {CookieService} from "ngx-cookie-service";
import {ActivatedRoute, Router} from "@angular/router";
import { SSOLogonService } from '../../service/ssologon.service';
import { LoginDataService } from '../../service/loginData.service';
import { UserDetailsService } from '../../service/userDetails.service';
import { AppConfigService } from '../../service/AppConfigService';

@Component({
  selector: 'app-oauth2-component',
  templateUrl: './oauth2-component.component.html',
  styleUrls: ['./oauth2-component.component.scss']
})
export class OAuth2ComponentComponent implements OnInit {

  code: string;
  id_token: any;
  access_token: string;
  refresh_token: string;
  myRawToken: any;
  firstName: string;
  uid: string;
  surname: string;
  displayLoginName: string;
  email: string;
  status: string;
  roleId: string;
  cookieValue = 'UNKNOWN';
  baseUrl:any;
  trimurl:any;
  ssourl:any;
  uiurl:any;
  constructor(private ssologonService: SSOLogonService,
              private cookieService: CookieService,
              private route: ActivatedRoute,private config: AppConfigService,
              private router: Router,private loginService: LoginDataService, private userDetailsService: UserDetailsService) {
               
                //this.baseUrl = "http://localhost:8080";
                //this.uiurl = "http://localhost:4200";           
               // this.ssourl = "https://sso.test.det.nsw.edu.au/sso";
               // this.trimurl="https://ece-trim-api-ece-census.cloudapps.test.nsw.education";
                  this.baseUrl = this.config.getConfig().baseUrl;
                this.trimurl=this.config.getConfig().trimurl;
                this.uiurl=this.config.getConfig().uiurl;
                this.ssourl=this.config.getConfig().ssoURL;
           
               }

  ngOnInit(): void {
    
    this.route.queryParams.subscribe(
      (params) => {
        this.code = params['code'];
     
       // console.log('SSO code: ' + params['code']);
      }
    )
    this.ssologonService.getOAuth2AccessToken(this.code).subscribe(
      result => {
       
     //    console.log('getOAuth2AccessToken RESULT: ' + JSON.stringify(result, undefined, 2));
        
         var stringifiedData = JSON.stringify(result);
         var parsedJson = JSON.parse(stringifiedData);
      //   console.log('getOAuth2AccessToken RESULT: idtoken' + parsedJson['id_token']);
         
        this.id_token = parsedJson['id_token'];
    //    console.log('SSO id_token: ' + this.id_token);
        this.access_token = parsedJson['access_token'];
      //  console.log('SSO access_token: ' + this.access_token);
        this.refresh_token = parsedJson['refresh_token'];
     //   console.log('SSO refresh_token: ' + this.refresh_token);
        this.myRawToken = this.id_token.toString();
        localStorage.setItem('access_token',this.access_token);
        localStorage.setItem('id_token',this.id_token);

        localStorage.setItem('baseUrl',this.baseUrl);
        localStorage.setItem('trimurl',this.trimurl);
        localStorage.setItem('uiurl',this.uiurl);
        localStorage.setItem('ssourl',this.ssourl);
        //set this to service object
        this.loginService.setToken(this.myRawToken);
        //alert(this.loginService.getToken());
        // this.decodedAccessToken = JSON.stringify(result, undefined, 2);
        // this.router.navigate(['/ssoLogin']);
        const helper = new JwtHelperService();
        const decodedIdToken = helper.decodeToken(this.myRawToken);
        
        const expirationDate = helper.getTokenExpirationDate(this.myRawToken);
        const isExpired = helper.isTokenExpired(this.myRawToken);
        this.firstName = decodedIdToken.givenName;
        this.uid=decodedIdToken.uid;
        this.surname = decodedIdToken.sn;
        this.displayLoginName = this.firstName + ' ' + this.surname;
        this.email = decodedIdToken.email;
        // this.serviceProvider.displayname = this.displayLoginName;
        // this.ssoAuthComponent.displayLoginName = this.displayLoginName;
        // setting new item
      
        var role ='';
       // alert("calling getUserforId"+this.uid);
       // this.userDetailsService.getUserforId(this.uid).toPromise().then( async params => {

          
      
    
         /* this.loginService.setData({firstName:this.firstName,
            surname:this.surname,
            displayLoginName:this.displayLoginName,
            email:this.email,
            status:params.status,
            roleId: params.roleId,
            uid:this.uid
  
          });

          this.status = params.status;
          this.roleId = params.roleId;*/

      //  console.log('values.. role and name ' + this.loginService.getData().roleId);
       //  alert('values.. role and name ' + this.loginService.getData().roleId);
       //   role=this.loginService.getData().roleId;
       //   alert("calling getAccessDetails"+role);
      //   await this.userDetailsService.getAccessDetails("ECOMP_"+role).toPromise().then( (data) => {
          //  for (const d of (data as any)) {
    
              
             // this.loginService.getAccessList().push(d.pageName);
    
    
           // }
        //  });
       
          
        console.log("DECODED TOKEN: " + this.email);
        console.log("DECODED TOKEN name toString(): " + this.displayLoginName);
        console.log("DECODED TOKEN expirationDate: " + expirationDate);
        console.log("DECODED TOKEN isExpired: " + isExpired);
        localStorage.setItem('displayLoginName', this.displayLoginName);
        localStorage.setItem('userEmail', '('+this.email+')');
        localStorage.setItem('firstName', this.firstName);
        localStorage.setItem('surname', this.surname);
        localStorage.setItem('status', this.status);
        //localStorage.setItem('roleId', this.roleId);
        localStorage.setItem('uid', this.uid);

        if (isExpired)
          localStorage.setItem('isExpired', "expired");
          
          const next = localStorage.getItem('oauth-redirect-after-callback') || '/main/qiip/dashboard'
          localStorage.removeItem('oauth-redirect-after-callback')
        this.router.navigateByUrl(next);
        //  })  ;  

      }, error => { 
       
        localStorage.removeItem('displayLoginName');
        console.log('getOAuth2AccessToken ERROR' + error);
      });

  }



  getLogin() {


    this.ssologonService.getLogin() ; //(
    // result => {
    //   console.log('RESULT' + result);
    //
    //   // this.router.navigate(['/ssoLogin']);
    //   //   if (result.valid == 'false') {
    //   //     console.log('FALSE VALUE');
    //   //   } else if (result.valid == 'true') {
    //   //     console.log('TRUE VALUE');
    //   //   }
    // }, error => {console.log('ERROR' + error);});
    // this.cookieService.set( 'iPlanetDirectoryPro', this.cookieService.get('iPlanetDirectoryPro') );
    this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
    console.log('COOKIE ' + this.cookieValue);
  }

 

}
