import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Visit} from '../domain/visit';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/demo/service/AppConfigService';

//const getAllUrl = "http://localhost:8080"

@Injectable({
    providedIn: 'root'
})
export class ServiceDetailsService {

    serviceUrl:any;
    constructor(private http: HttpClient,private config: AppConfigService) {

        this.serviceUrl= this.config.getConfig().baseUrl;
      }
    getServiceDetails(serviceId:string): Observable<any>  {
     
        return this.http.get<any>(this.serviceUrl+'/api/service-details/'+serviceId);
                   
    }
  
    
}