import { Component,OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginDataService } from '../demo/service/loginData.service';
import {LogonService} from '../demo/service/logon.service';
import {ExternalAccessDetailsService} from '../../app/demo/service/externalaccessdetails.service';
import { PortalMessageService } from '../demo/service/portalmessage.service';
import { IPortalMessage } from '../demo/domain/portalmessage';
import { Message,MessageService } from 'primeng/api';
import { DynaSurveyService } from '../demo/service/dyna-survey.service';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-survey',
  styleUrls: ['./app.survey.component.scss'],
  templateUrl: './app.survey.component.html',
  providers: [MessageService]
})
export class AppSurveyComponent implements OnInit , OnDestroy {
  servicenameFlag:false;
  servicenameparam:any;
  serviceId:any;
  visitId:any;
  portalMessage:IPortalMessage  ={};
  portalmessId:string;
  password:any;
  msgs1: Message[];    
  alertmessages:string;
  submitted:boolean=false;
  emptymessage:boolean = false;
  constructor(private route: ActivatedRoute,private router: Router,private loginService:LoginDataService
    , private dynasurveyservice : DynaSurveyService, private messageService: MessageService
    ,private portalMessageService :PortalMessageService, private primengConfig: PrimeNGConfig, private logonservice: LogonService) { 
   
  }
    ngOnDestroy(): void {
        localStorage.removeItem('isextlanding');
    }
    ngOnInit(): void {

        localStorage.setItem('isextlanding','true');
        this.route.queryParams.subscribe(params => {
            this.servicenameparam = params['servicename'];
           
        });

        this.portalmessId ='PORTL000012';
        this.portalMessageService.get(this.portalmessId).subscribe(data => {
            this.portalMessage = data;
            this.alertmessages ='Testing messages';
            if(this.portalMessage.messageDets.length > 0){

                this.emptymessage = true;
            }

        this.msgs1 = [
            {severity:'success', summary:'', detail:this.portalMessage.messageDets}
            
        ];

        this.primengConfig.ripple = true;
        });
        
        
    }
    validateserDets()
    {

        this.submitted=true;
      
        if(this.serviceId==undefined || this.serviceId==null)
        {
            this.serviceId='';
        }
        if(this.visitId==undefined || this.visitId==null)
        {
            this.visitId='';
        }
     
        localStorage.setItem("serviceId",this.serviceId);
        localStorage.setItem("visitId",this.visitId);
        localStorage.setItem("password","survey"); //setting this for backend validation for DB access

           //code to validate the service id, password and asr id
        if(this.serviceId!=null && this.serviceId.trim()!='' 
        && this.visitId!=null && this.visitId.trim()!='' )
      
    {
        
        this.dynasurveyservice.getDynaSurveyForvisitId(this.visitId,this.serviceId).subscribe(data=>{
         
            if(data!=undefined && data!=null)
            {
               
               

                //check if the url is invalid

                if(data.status!=null && data.status=='Submitted')
                {
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access unavailable - Survey form already sunmitted' });
                    this.submitted=false;
                    return;
                }

                //check expiry date

                if(data.expiryDate!=null)
                {

                    let currentDate = new Date();
                    let expiryDate = new Date(data.expiryDate);
                    expiryDate.setHours(0, 0, 0, 0);
                    currentDate.setHours(0, 0, 0, 0);
    
                    if(expiryDate<currentDate)
                    {
                        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access unavailable - Survey has expired' });
                        this.submitted=false;
                        return;
                    }

                }

               

               
              
                localStorage.setItem("serviceId",this.serviceId);
                localStorage.setItem("visitId",this.visitId);
                localStorage.setItem("surveyId",data.surveyId);
            
                
            
                this.router.navigate(['main/externalaccess/appsurveyform']);

            }
            else{
                
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check your ASR Number, Service ID and Password.' });
                this.submitted=false;
            }
        }
        ,err=>{
            console.log(err);
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Internal error: '+err });
            this.submitted=false;
  
        })
    }else{
        this.submitted=false;
    }

       
       
     
     
        
    }
}
