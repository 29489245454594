import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IServiceProvider } from '../domain/service-provider';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const serviceUrl =  '/api/external-access-services';

@Injectable({
  providedIn: 'root'
})
export class ExternalAccessDetailsService {

    
  baseUrl:any
  constructor(private http: HttpClient,private config: AppConfigService) {

    this.baseUrl= this.config.getConfig().baseUrl;
  

  }
  getServiceDetails(serviceId:string,visitId:string,password:string): Observable<any>  {
  
    return this.http.get<any>(this.baseUrl+serviceUrl+'/'+serviceId+'/'+visitId+'/'+password);
               
}

getForServiceASR(serviceId:string,visitId:string): Observable<any>  {
 
  return this.http.get<any>(this.baseUrl+serviceUrl+'/'+serviceId+'/'+visitId);
             
}

  updateAccessDetails(data:any): Observable<any>  {
    
    return this.http.put<any>(this.baseUrl+serviceUrl,data);
               
}


}
