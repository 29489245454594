import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDynaFormElements } from '../domain/dyna-form-elements';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const serviceUrl =  '/api/dyna-form-elements';

@Injectable({
  providedIn: 'root'
})
export class DynaFormElementsService {
 
  baseUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {
    this.baseUrl = this.config.getConfig().baseUrl;
  }
  getAllDynaFormElements(): Observable<any> {
    return this.http.get<IDynaFormElements[]>(this.baseUrl+serviceUrl);
  }

  getDynaFormElementsForvisitId(eleId:any): Observable<any> {
    return this.http.get<IDynaFormElements[]>(this.baseUrl+serviceUrl+'/all/'+eleId);
}


  getDynaFormElementsDetail(id:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/'+ id);
  }

  createDynaFormElements(data:any): Observable<any> {
    return this.http.post(this.baseUrl+serviceUrl, data);
  }

  updateDynaFormElements(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl, data);
  }
}
