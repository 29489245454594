import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDynaFormValues } from '../domain/dyna-form-values';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const serviceUrl =  '/api/dyna-form-values';

@Injectable({
  providedIn: 'root'
})
export class DynaFormValuesService {
 
  baseUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {
    this.baseUrl = this.config.getConfig().baseUrl;
  }
  getAllDynaFormValues(): Observable<any> {
    return this.http.get<IDynaFormValues[]>(this.baseUrl+serviceUrl);
  }

  getDynaFormValuesForsurveyId(surveyId:any): Observable<any> {
    return this.http.get<IDynaFormValues[]>(this.baseUrl+'/api/dyna-form-values/all/'+surveyId);
}


  getDynaFormValuesDetail(id:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/'+ id);
  }

  createDynaFormValues(data:any): Observable<any> {
    return this.http.post(this.baseUrl+serviceUrl, data);
  }

  updateDynaFormValues(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl, data);
  }
}
