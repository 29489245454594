
import {Component,OnInit} from '@angular/core';
import {Location} from '@angular/common';

@Component({
    templateUrl: './app.doc.component.html',
})
export class AppDocumentComponent implements OnInit {

    constructor(private _location: Location) 
    {}

    ngOnInit() {

        window.open("assets/helpPages/Self Assessment and Quality Improvement Planning Portal User Guide.pdf", "_blank");
        this._location.back();
    }

}


