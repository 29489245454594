import { Component,OnDestroy,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginDataService } from '../demo/service/loginData.service';
import {LogonService} from '../demo/service/logon.service';
import { PortalMessageService } from '../demo/service/portalmessage.service';
import { IPortalMessage } from '../demo/domain/portalmessage';
import {ExternalAccessDetailsService} from '../../app/demo/service/externalaccessdetails.service'
import { Message, MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';


@Component({
  selector: 'app-externalaccess',
  styleUrls: ['./app.externalaccess.component.scss'],
  templateUrl: './app.externalaccess.component.html',
  providers: [MessageService]
})
export class AppExternalAccessComponent implements OnInit , OnDestroy {
  servicenameFlag:false;
  emptymessage:boolean = false;
  servicenameparam:any;
  serviceId:any;
  visitId:any;
  portalMessage:IPortalMessage  ={};
  portalmessId:string;
  password:any;
  msgs1: Message[];    
  alertmessages:string;
  submitted:boolean=false;
  constructor(private route: ActivatedRoute,private router: Router,private loginService:LoginDataService,private portalMessageService :PortalMessageService,
    private externalaccessservice : ExternalAccessDetailsService, private messageService: MessageService,private primengConfig: PrimeNGConfig,
    private logonservice: LogonService) { 
   
  }
    ngOnDestroy(): void {
        localStorage.removeItem('isextlanding');
    }
    ngOnInit(): void {

       // localStorage.clear();

        localStorage.setItem('isextlanding','true');
        localStorage.removeItem('serviceId');
        this.route.queryParams.subscribe(params => {
            this.servicenameparam = params['servicename'];
           
        });

        this.portalmessId ='PORTL000012';
        this.portalMessageService.get(this.portalmessId).subscribe(data => {
            this.portalMessage = data;
            this.alertmessages ='Testing messages';
            if(this.portalMessage.messageDets.length > 0){

                this.emptymessage = true;
            }
        this.msgs1 = [
            {severity:'success', summary:'', detail:this.portalMessage.messageDets}
            
        ];

        this.primengConfig.ripple = true;
        });
        
        
    }
    validateservice()
    {
        this.submitted=true;
        console.log("this.serviceId!"+this.serviceId)
        console.log("this.visitId!"+this.visitId)

        console.log("this.password!"+this.password)

        if(this.serviceId==undefined || this.serviceId==null)
        {
            this.serviceId='';
        }
        if(this.visitId==undefined || this.visitId==null)
        {
            this.visitId='';
        }
        if(this.password==undefined || this.password==null)
        {
            this.password='';
        }
           //code to validate the service id, password and asr id
        if(this.serviceId!=null && this.serviceId.trim()!='' 
        && this.visitId!=null && this.visitId.trim()!='' 
        && this.password!=null && this.password.trim()!='')
    {
        localStorage.setItem("serviceId",this.serviceId);
        localStorage.setItem("visitId",this.visitId);
        localStorage.setItem("password",this.password);
        
        this.externalaccessservice.getServiceDetails(this.serviceId,this.visitId,this.password).subscribe(data=>{
         
            if(data!=undefined && data!=null)
            {
                console.log("test...")
                //check if the url is invalid

                if(data.completeFlag!=null && data.completeFlag=='Y')
                {
                    localStorage.removeItem('serviceId');
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Access unavailable - SA/SC form already submitted' });
                    this.submitted=false;
                    
                    return;
                }

                let today = new Date();
                var validtilldate = new Date(data.validEndDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3"))
                validtilldate.setHours(0, 0, 0, 0);
                today.setHours(0, 0, 0, 0);

                if (today > validtilldate) {
                    localStorage.removeItem('serviceId');
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Your access has expired. Please contact the Network Coordination team for assistance.' });
                    this.submitted=false;
                 
                    return;
                }

                localStorage.setItem('firstName',this.servicenameparam);
                localStorage.setItem('surname','');
              
              

                
                //create empty initial table records
                this.logonservice.create(this.serviceId,this.visitId).subscribe(resp=>{
                        console.log("initial data tables created");
                          //update Accessedflag
                                data.assessedFlag = 'Yes';
                                data.access_timestamp= new Date();
                                data.updatedBy="System";
                                data.updatedDate = new Date();
                                this.externalaccessservice.updateAccessDetails(data).subscribe(resp2 =>{
                                    console.log("Assessed Flag updated");

                                },err=>{
                                    console.log("Assessed Flag updated failed"); 
                                })
                        this.router.navigate(['main/externalaccess/welcome']);
                },err=>{
                    localStorage.removeItem('serviceId');
                    console.log("create empty initial table records failed"); 
                    this.submitted=false;
                })

              


            }
            else{
                localStorage.removeItem('serviceId');
                this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check your ASR Number, Service ID and Password.' });
                this.submitted=false;
               
            }
        },err=>{
            console.log("internal server error externalaccessservice.getServiceDetails ");
            localStorage.removeItem('serviceId');
          // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Internal error: from server' });
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please check your ASR Number, Service ID and Password.' });
          this.submitted=false;  
         
        })
    }else{
        localStorage.removeItem('serviceId');
        this.submitted=false;
    }

       
       
     
     
        
    }
}
