
import {Component,OnInit} from '@angular/core';
import {MenuItem, MessageService} from 'primeng/api';
//import { ServiceDetailsService } from '../../app/demo/service/serviceDetailsService';
import { Router } from '@angular/router';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['home.component.scss'],
    providers: [MessageService]
})
export class HomeComponent implements OnInit {
    city: string;
    inputServiceId:string='';
    inputAsrId:string='';
    serviceName:string='';
    constructor(  private router: Router, public messageService: MessageService ) {}
    ngOnInit() {
      //this.router.navigate(['/main/visitlist']); 
         
       // this.inputServiceId = localStorage.getItem('serviceId');
       // this.inputAsrId = localStorage.getItem('visitId');

       /* this.servicedetailsSrvc.getServiceDetails(this.inputServiceId).subscribe(data=>{
            this.serviceName=data.serviceName;
        });*/

    }

}


