import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDynaSurvey } from '../domain/dyna-survey';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const serviceUrl =  '/api/dyna-surveys';

@Injectable({
  providedIn: 'root'
})
export class DynaSurveyService {
 
  baseUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {
    this.baseUrl = this.config.getConfig().baseUrl;
  }
  getAllDynaSurvey(): Observable<any> {
    return this.http.get<IDynaSurvey[]>(this.baseUrl+serviceUrl);
  }

  getDynaSurveyForvisitId(asrId:any,serviceId:any): Observable<any> {
    return this.http.get<IDynaSurvey>(this.baseUrl+serviceUrl+'/all/'+ asrId+'/'+serviceId);
  }


  getDynaSurveyDetail(id:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/'+ id);
  }

  createDynaSurvey(data:any): Observable<any> {
    return this.http.post(this.baseUrl+serviceUrl, data);
  }

  updateDynaSurvey(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl, data);
  }

  getCurrentDynaSurvey(asrId:any,serviceId:any): Observable<any> {
    return this.http.get<IDynaSurvey>(`${this.baseUrl}${serviceUrl}/${asrId}/${serviceId}/current`);
  }
}
