import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';
const baseUrl =  '/api/logon-service';

@Injectable({
  providedIn: 'root'
})
export class LogonService {

  serviceUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {

    this.serviceUrl= this.config.getConfig().baseUrl;
  }


  
  create(serviceId:any,visitId:any): Observable<any> {
    return this.http.post(this.serviceUrl+baseUrl+'/'+serviceId+'/'+visitId, null);
  }


}