import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExternalAccessDetailsService } from '../demo/service/externalaccessdetails.service';


@Component({
  selector: 'app-fbconfirmationmsg',
  styleUrls: ['./app.fbconfirmationmsg.component.scss'],
  templateUrl: './app.fbconfirmationmsg.component.html',
  providers: [MessageService]
})
export class FBConfirmationMessageComponent implements OnInit  {
  sacheck:any;
  serviceId:any;
  visitId:any;
  password:any;
  sacheckFlag:boolean = false;
  portalFlag='exist';
  constructor(private externalaccessservice : ExternalAccessDetailsService,private router: Router,private messageService: MessageService) { 
  
  }
    ngOnInit(): void {
     // localStorage.clear();
     this.serviceId = localStorage.getItem('serviceId');
      this.visitId = localStorage.getItem('visitId');

       this.externalaccessservice.getForServiceASR(this.serviceId, this.visitId).subscribe(ext=>{
        if(ext!=null)
        {
            this.portalFlag = ext.commencementLetterFlag;

            localStorage.removeItem('serviceId');
            localStorage.removeItem('visitId');
            localStorage.removeItem('password');
        }
    })
      window.onpopstate = function (e) { window.history.forward(); }
     
        
    }
    
}
