import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortalService } from 'src/app/services/portal.service';

@Component({
  selector: 'app-back-to-portal',
  templateUrl: './back-to-portal.component.html',
  styleUrls: ['./back-to-portal.component.scss']
})
export class BackToPortalComponent implements OnInit {

  constructor(private portalService: PortalService, private router: Router) { }

  ngOnInit(): void {
  }

    backToPortal () {
      const serviceId = this.portalService.getServiceId();
      this.router.navigate(['/main/qiip/dashboard'], {queryParams: {serviceId}})
    }

    get isRedirectedFromPortal() {
      return !!this.portalService.isPortal()
    }
}
