import {Component, OnInit} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginDataService } from './demo/service/loginData.service';
import { SSOLogonService } from './demo/service/ssologon.service';
import { ServiceDetailsService } from './demo/service/serviceDetailsService';
import { PortalService } from './services/portal.service';

//import { UserDetailsService } from './demo/service/userDetails.service';


@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {
    userId:string;
    loggedinUser:string;
    isexternalaccess=false;
    issurvey=false;
    isqiip=false;
    inputServiceId="";
    servicetype="";
    constructor(public app: AppMainComponent,   private servicedetailsSrvc: ServiceDetailsService,
        private ssologonservice:SSOLogonService,private route: Router,private activatedRoute: ActivatedRoute,private loginService:LoginDataService, public portalService: PortalService) {}


        async ngOnInit() {
             

            if(this.loginService.getData() == null || this.loginService.getData() == undefined){

               console.log(localStorage.getItem('displayLoginName'));
              //this.loginService.setAccessList([]);
             this.loginService.setData({firstName:localStorage.getItem('firstName'),
               surname:localStorage.getItem('surname'),
               displayLoginName:localStorage.getItem('displayLoginName'),
               email:localStorage.getItem('email'),
             //  status:localStorage.getItem('status'),
              // roleId:localStorage.getItem('roleId'),
               uid:localStorage.getItem('uid')
              
               //roleId: localStorage.getItem('roleId')
          
             });
          
             this.userId=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
             this.loggedinUser=this.loginService.getData().uid;
             //this.role = this.loginService.getData().roleId;
           
            }else{
            
             this.userId=this.loginService.getData().firstName+"."+this.loginService.getData().surname;
             this.loggedinUser=this.loginService.getData().uid;
             //this.role = this.loginService.getData().roleId;
           
            }
           console.log("this.route.url"+this.route.url)
           // await this.setAccess();
           if(this.route.url.includes("externalaccess"))
           {
            this.isexternalaccess=true;

           }
           if(this.route.url.includes("survey"))
           {
            this.issurvey=true;

           }
           if(this.route.url.includes("qiip"))
           {
            this.isqiip=true;
        
           }
           
         
          }
      
          getLogout() {
            this.ssologonservice.getLogout();
            // this.cookieValue = this.cookieService.get('iPlanetDirectoryPro');
            // console.log('COOKIE' + this.cookieValue);
          }
        
          getlink()
          {
              if(localStorage.getItem('isextlanding')!=undefined && localStorage.getItem('isextlanding')!=null)
                return false;
              else
              return true;
          }
          isserviceknown()
          {
            if(window.location.href.indexOf("feedback")<=-1)
            {
            this.inputServiceId = localStorage.getItem('serviceId');
            if(this.inputServiceId !=undefined && this.inputServiceId !=null)
            {
              return true;
            }else{
              return false;
            }
           }
           else{
            return true;
           }

          }

          isqip()
          {
            if(window.location.href.indexOf("qiip")>-1)
            {
              return true;
            
           }
           else{
            return false;
           }

          }
          openhelp()
          {
         
        
            if (window.location.href.indexOf("responsetodraft") > -1 || window.location.href.indexOf("feedback")>-1) {
             
              window.open("assets/helpPages/Feedback-Submission-Guide.pdf", "_blank");
            }
            else{
            
           // var fileURL = URL.createObjectURL("assets/helppages/Self-Assessment-GuideNN.pdf");
           this.inputServiceId = localStorage.getItem('serviceId');
          
           this.servicedetailsSrvc.getServiceDetails(this.inputServiceId).subscribe(data => {
            this.servicetype = data.serviceType;
           
            if(this.servicetype!=null && this.servicetype==="Out of Scope")
            {
             window.open("assets/helpPages/Mobile Service - Service Context and Self-Assessment Guide.pdf", "_blank");
            }
            else
            {
             window.open("assets/helpPages/Service Context and Self-Assessment Guide.pdf", "_blank");
            }
           },err=>{
             console.log(err);
           })
          
            }
         
           
          }

          openhelpportal()
          {
         
            console.log("i am in openhelpportal....")
        
            if (window.location.href.indexOf("responsetodraft") > -1 || window.location.href.indexOf("feedback")>-1) {
             
              window.open("assets/helpPages/Feedback Submission Guide-Portal.pdf", "_blank");
            }
            else{
            
           // var fileURL = URL.createObjectURL("assets/helppages/Self-Assessment-GuideNN.pdf");
           this.inputServiceId = localStorage.getItem('serviceId');
          
           this.servicedetailsSrvc.getServiceDetails(this.inputServiceId).subscribe(data => {
            this.servicetype = data.serviceType;
           
            if(this.servicetype!=null && this.servicetype==="Out of Scope")
            {
             window.open("assets/helpPages/Mobile Service - Service Context and Self-Assessment Guide-Portal.pdf", "_blank");
            }
            else
            {
             window.open("assets/helpPages/Service Context and Self-Assessment Guide-Portal.pdf", "_blank");
            }
           },err=>{
             console.log(err);
           })
          
            }
         
           
          }

          goToHome(event) {
            event.preventDefault()
            if (this.portalService.isPortal()) {
              const serviceId = this.portalService.getServiceId();
              this.route.navigate(['/main/qiip/dashboard'], {queryParams: {serviceId}})
            } else {
              this.route.navigate(['/'])
            }
          }

    }
