import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRoute, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { SSOLogonService } from '../demo/service/ssologon.service';
import {Observable} from 'rxjs'
@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanLoad {
  constructor(private auth: SSOLogonService, private router: Router) {}
  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (!this.auth.isAuthenticated()) {
        const fullPath = `${window.location.pathname}${window.location.search}`
        localStorage.setItem('oauth-redirect-after-callback', fullPath)
        // this.router.navigate(['login']);
        this.auth.getLogin()

        return false;
      }
      return true;
  }
  // canActivate(): boolean {
  //   if (!this.auth.isAuthenticated()) {
  //     const fullPath = `${window.location.pathname}${window.location.search}`
  //     localStorage.setItem('oauth-redirect-after-callback', fullPath)
  //     // this.router.navigate(['login']);
  //     this.auth.getLogin()

  //     return false;
  //   }
  //   return true;
  // }
}