//import {NgModule} from '@angular/core';
import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import {FormsModule} from '@angular/forms';
//import {HttpClientModule} from '@angular/common/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import { DatePipe } from '@angular/common'
import {CookieService} from 'ngx-cookie-service';
import {MessageService} from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
//import {AccordionModule} from 'primeng/accordion';
//import {AutoCompleteModule} from 'primeng/autocomplete';
//import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
//import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
//import {CarouselModule} from 'primeng/carousel';
//import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
//import {ChipsModule} from 'primeng/chips';
//import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
//import {ColorPickerModule} from 'primeng/colorpicker';
//import {ContextMenuModule} from 'primeng/contextmenu';
//import {DataViewModule} from 'primeng/dataview';
//import {DialogModule} from 'primeng/dialog';
//import {DropdownModule} from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import {FieldsetModule} from 'primeng/fieldset';
//import {FileUploadModule} from 'primeng/fileupload';
//import {FullCalendarModule} from 'primeng/fullcalendar';
//import {GalleriaModule} from 'primeng/galleria';
//import {InplaceModule} from 'primeng/inplace';
//import {InputNumberModule} from 'primeng/inputnumber';
//import {InputMaskModule} from 'primeng/inputmask';
//import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
//import {LightboxModule} from 'primeng/lightbox';
//import {ListboxModule} from 'primeng/listbox';
//import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
//import {MultiSelectModule} from 'primeng/multiselect';
//import {OrderListModule} from 'primeng/orderlist';
//import {OrganizationChartModule} from 'primeng/organizationchart';
//import {OverlayPanelModule} from 'primeng/overlaypanel';
//import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
//import {PanelMenuModule} from 'primeng/panelmenu';
//import {PasswordModule} from 'primeng/password';
//import {PickListModule} from 'primeng/picklist';
//import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
//import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
//import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
//import {SidebarModule} from 'primeng/sidebar';
//import {SlideMenuModule} from 'primeng/slidemenu';
//import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
//import {TerminalModule} from 'primeng/terminal';
//import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
//import {TreeModule} from 'primeng/tree';
//import {TreeTableModule} from 'primeng/treetable';
//import {VirtualScrollerModule} from 'primeng/virtualscroller';
import { OAuth2ComponentComponent } from './demo/security/oauth2-component/oauth2-component.component';
import {AppCodeModule} from './app.code.component';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent} from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import {AppBreadcrumbComponent} from './app.breadcrumb.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
//import {DashboardComponent} from './demo/view/dashboard.component';
/*import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';*/
//import {PreVisitDetailsComponent} from './demo/view/previsitdetails.component';
//import {PostVisitDetailsComponent} from './demo/view/postvisitdetails.component';
//import {PeerReviewComponent} from './demo/view/peerreview.component';
//import {FDCPreVisitDetailsComponent} from './demo/view/fdcprevisitdetails.component';
//import {FDCPostVisitDetailsComponent} from './demo/view/fdcpostvisitdetails.component';
//import {FDCVisitlistComponent} from './demo/view/fdcvisitlist.component';
import {HomeComponent} from './demo/view/home.component';
//import {VisitListComponent} from './demo/view/visitlist.component';
//import {FinishedVisitListComponent} from './demo/view/finishedvisitlist.component';
//import {EmailtemplateComponent} from './demo/view/emailtemplate.component';
//import {DocumentationComponent} from './demo/view/documentation.component';
/*import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';

import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';*/
import {AppHelpComponent} from './pages/app.help.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';

//import {CountryService} from './demo/service/countryservice';
//import {CustomerService} from './demo/service/customerservice';
//import {EventService} from './demo/service/eventservice';
//import {IconService} from './demo/service/iconservice';
//import {NodeService} from './demo/service/nodeservice';
//import {PhotoService} from './demo/service/photoservice';
//import {ProductService} from './demo/service/productservice';
//import {CarService} from './demo/service/carservice';

import { AssessmentVisitService } from './demo/service/assessmentvisitservice';
import { ServiceDetailsService } from './demo/service/servicedetails.service';
import { ServiceStaffDetailsService } from './demo/service/servicestaffdetails.service';
import { ServiceNomSubDetailService } from './demo/service/servicenomsupdetails.service';
import { ServiceProviderService } from './demo/service/serviceprovider.service';
import { NQAITSSyncService } from './demo/service/nqaitsService.service';
import { ServiceContextTypesService } from './demo/service/servicecontexttypes.service';
import { AttachmentsService } from './demo/service/attachments.service';
import { EmailtemplateService } from './demo/service/emailtemplate.service';
import { EvidenceService } from './demo/service/evidence.service';
import { ExternalAccessService } from './demo/service/externalaccess.service';
import { TrimService } from './demo/service/trimService';
import { ElementsService } from './demo/service/elements.service';
import { StandardQuestionsService } from './demo/service/standardquestions.service';
import { StandardsThemesService } from './demo/service/standardsthemes.service';
import { QipDetailsService } from './demo/service/qip-details.service';
import { StdLawRegLinkService } from './demo/service/stdlawreglinkservice';
import { StandardElementsLinkService } from './demo/service/standardelementslink.service';
import { SummaryDetailsService } from './demo/service/summarydetails.service';
import { ClarificationDetailsService } from './demo/service/clarificationdetails.service';
import { StdLawRegulationsService } from './demo/service/stdlawregulations.service';
import { FdcPremisesService } from './demo/service/fdcpremises.service';
import { TrimStructureService } from './demo/service/trimstructure.service';
import { VisitQaStdLinkService } from './demo/service/visitqastdlink.service';
import {AppBreadcrumbService} from './app.breadcrumb.service';
import {MenuService} from './app.menu.service';
import { LoginDataService } from './demo/service/loginData.service';
import { AppConfigService } from './demo/service/AppConfigService';
import { TokenInterceptorService } from './demo/service/TokenInterceptorService';
import { AppSurveyComponent } from './pages/app.survey.component';
import { AppSurveyFormComponent } from './pages/app.surveyform.component';
import { AppDraftResponseComponent } from './pages/app.draftresponse.component';
import { AppExternalAccessComponent } from './pages/app.externalaccess.component';
import { AppExtWelcomeComponent } from './pages/app.extwelcome.component';
import { ConfirmationMessageComponent } from './pages/app.confirmationmsg.component';
import { FBConfirmationMessageComponent } from './pages/app.fbconfirmationmsg.component';
import { FBNoSelectionMessageComponent } from './pages/app.fbnoselectionmsg.component';
import { FBExitMessageComponent } from './pages/app.fbexitmsg.component';
import { SurveyConfirmationMessageComponent } from './pages/app.surveyconfirmationmsg.component';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { BackToPortalComponent } from './demo/components/common/back-to-portal.component';
import { PortalAccessdeniedComponent } from './pages/app.portalaccessdenied.component';


export function initConfig(appConfig: AppConfigService) {
    return () => appConfig.loadConfig();
  }

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
       // AccordionModule,
       // AutoCompleteModule,
       //BreadcrumbModule,
        ButtonModule,
        //CalendarModule,
        CardModule,
        //CarouselModule,
       // ChartModule,
        CheckboxModule,
      //  ChipsModule,
      //  CodeHighlighterModule,
        ConfirmDialogModule,
        //ColorPickerModule,
       // ContextMenuModule,
       // DataViewModule,
        //DialogModule,
       // DropdownModule,
        EditorModule,
        FieldsetModule,
       // FileUploadModule,
        //FullCalendarModule,
        //GalleriaModule,
        //InplaceModule,
        //InputNumberModule,
        //InputMaskModule,
        //InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        //LightboxModule,
       // ListboxModule,
        //MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
       // MultiSelectModule,
       // OrderListModule,
        //OrganizationChartModule,
        //OverlayPanelModule,
        //PaginatorModule,
        PanelModule,
       // PanelMenuModule,
        //PasswordModule,
        //PickListModule,
        //ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
       // RatingModule,
        RippleModule,
       // ScrollPanelModule,
        SelectButtonModule,
       // SidebarModule,
        //SlideMenuModule,
        //SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        //TerminalModule,
        //TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        //TreeModule,
        //TreeTableModule,
        //VirtualScrollerModule,
        AppCodeModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppConfigComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
        //DashboardComponent,
        /*FormLayoutDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MenusDemoComponent,
        MediaDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,*/
       // PreVisitDetailsComponent,
       // PostVisitDetailsComponent,
       // PeerReviewComponent,
       // VisitListComponent,
      //  FDCPreVisitDetailsComponent,
      //  FDCPostVisitDetailsComponent,
      //  FDCVisitlistComponent,
      //  FinishedVisitListComponent,
      HomeComponent,
        OAuth2ComponentComponent,
       // EmailtemplateComponent,
      //  DocumentationComponent,
        AppLoginComponent,
       /* FileDemoComponent,
        
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        WidgetsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        AppCrudComponent,
        AppCalendarComponent,
       
        AppInvoiceComponent,*/
        AppHelpComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        PortalAccessdeniedComponent,
        AppSurveyComponent,
        AppSurveyFormComponent,
        AppDraftResponseComponent,
        ConfirmationMessageComponent,
        FBConfirmationMessageComponent,
        SurveyConfirmationMessageComponent,
        FBExitMessageComponent,
        FBNoSelectionMessageComponent,
        AppExternalAccessComponent,
        AppSurveyComponent,
        AppExtWelcomeComponent,
        BackToPortalComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: PathLocationStrategy},
       /* CountryService, CarService, CustomerService, EventService, IconService, NodeService,
        AssessmentVisitService, ServiceDetailsService, ServiceStaffDetailsService,NQAITSSyncService,StandardElementsLinkService,
        AttachmentsService,FdcPremisesService,EmailtemplateService,ElementsService, StandardQuestionsService,
        StandardsThemesService,EvidenceService,QipDetailsService,SummaryDetailsService,ClarificationDetailsService,
        StdLawRegLinkService, StdLawRegulationsService,TrimStructureService,VisitQaStdLinkService, TrimService,
        ServiceNomSubDetailService, ServiceProviderService, ServiceContextTypesService,ExternalAccessService,
        PhotoService, ProductService, */
        MenuService, 
        AppBreadcrumbService,MessageService,ConfirmationService,
        DatePipe,CookieService,LoginDataService,
        {
            provide: APP_INITIALIZER,
            useFactory: initConfig,
            deps: [AppConfigService],
            multi: true,
          },
          {
            provide: HTTP_INTERCEPTORS, 
            useClass: TokenInterceptorService, 
            multi: true
          },
          { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
          JwtHelperService
    ],
    bootstrap: [AppComponent],
    schemas: [ NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA ]
  })
export class AppModule { }
