import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { PortalService } from "src/app/services/portal.service";

@Injectable({
    providedIn: "root",
})
export class RedirectToPortalGuard implements CanActivate {
    constructor(private portalService: PortalService, private router: Router) {

    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {

        if (this.portalService.isPortal()) {
            const serviceId = this.portalService.getServiceId();
            this.router.navigate(["/main/qiip/dashboard"], {
                queryParams: { serviceId },
            });
        }
        return true;
    }
}
