import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IServiceEvents } from '../domain/service-events';
import { Observable } from 'rxjs';
import { AppConfigService } from 'src/app/demo/service/AppConfigService';

const baseUrl =  '/api/generate-report';
const baseDUrl = '/api/generate-reports-desk'
const uploadUrl =  '/api/upload-report';
const copyPortalData =  '/api/copyportaldata';
const uploadUrlFB =  '/api/upload-report-feedback';

const sendemail =  '/api/send-email';
const sendfbyesnoemail =  '/api/send-fbemail';
const sendSurveySubmitEmail = "/api/sendemail-submit-survey"

@Injectable({
  providedIn: 'root'
})
export class GenerateReportService {

  serviceUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {

    this.serviceUrl= this.config.getConfig().baseUrl;
  }

  getAll(): Observable<any> {
    return this.http.get<IServiceEvents[]>(this.serviceUrl+baseUrl);
  }


  getReport(type:string,serviceId:string,asrId:string,forACO:string, serviceType:String): Observable<any> {
    const httpOptions = {
      // 'responseType': 'blob' as 'json'
      'responseType'  : 'arraybuffer' as 'json'
      };

      return this.http.post(this.serviceUrl+baseUrl+"?type="+type+"&visitId="+asrId+"&serviceId="+serviceId+"&forACO="+forACO+"&serviceType="+serviceType,null,httpOptions);
  }

  getSelfAssessmentReport(serviceId: String): Observable<any> {

         const httpOptions = {
         'responseType': 'blob' as 'json',
            
         };
    return this.http.get(`${this.serviceUrl + baseUrl}/SELF_ASSESSMENT_REPORT_SERVICE/service/${serviceId}`, httpOptions)
  }

  getdeskReport(type:string,serviceId:string,asrId:string,forACO:string, serviceType:String): Observable<any> {
    const httpOptions = {
      // 'responseType': 'blob' as 'json'
      'responseType'  : 'arraybuffer' as 'json'
      };

      return this.http.post(this.serviceUrl+baseDUrl+"?type="+type+"&visitId="+asrId+"&serviceId="+serviceId+"&forACO="+forACO+"&serviceType="+serviceType,null,httpOptions);
  }

  uploadAndSendReport(type:string,serviceId:string,asrId:string,providerId:string, serviceType:String)
  {
  

    return this.http.post(this.serviceUrl+uploadUrl+"?type="+type+"&visitId="+asrId+"&serviceId="+serviceId+"&providerId="+providerId+"&serviceType="+serviceType,null);


  }

  copyPortalData(serviceId:string,asrId:string)
  {
    return this.http.post(this.serviceUrl+copyPortalData+"?visitId="+asrId+"&serviceId="+serviceId,null);

  }

  uploadAndSendReportFB(type:string,serviceId:string,asrId:string,providerId:string, serviceType:String)
  {
  

    return this.http.post(this.serviceUrl+uploadUrlFB+"?type="+type+"&visitId="+asrId+"&serviceId="+serviceId+"&providerId="+providerId+"&serviceType="+serviceType,null);


  }

  sendACOemail(visitId:string,serviceId:string,optflag:string)
  {
    return this.http.post(this.serviceUrl+sendemail+"/"+visitId+"/"+serviceId+"/"+optflag,null);

  }

  sendFbYesNoemail(visitId:string,serviceId:string,yesnoflag:string)
  {
    return this.http.post(this.serviceUrl+sendfbyesnoemail+"/"+visitId+"/"+serviceId+"/"+yesnoflag,null);

  }

  sendSurveySubmissionEmail(visitId:string,serviceId:string)
  {
    return this.http.post(this.serviceUrl+sendSurveySubmitEmail+"/"+visitId+"/"+serviceId,null);

  }

  create(data:any): Observable<any> {
    return this.http.post(this.serviceUrl+baseUrl, data);
  }

  update(data:any): Observable<any> {
    return this.http.put(this.serviceUrl+baseUrl, data);
  }

}
