import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IDynaFormElementsLov } from '../domain/dyna-form-elements-lov';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const serviceUrl =  '/api/dyna-form-elements-lovs';

@Injectable({
  providedIn: 'root'
})
export class DynaFormElementsLovService {
 
  baseUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {
    this.baseUrl = this.config.getConfig().baseUrl;
  }
  getAllDynaFormElementsLov(): Observable<any> {
    return this.http.get<IDynaFormElementsLov[]>(this.baseUrl+serviceUrl);
  }
  getDynaFormElementsForvisitId(eleId:any): Observable<any> {
    return this.http.get<IDynaFormElementsLov[]>(this.baseUrl+serviceUrl+'/all/'+eleId);
}

  getDynaFormElementsLovDetail(id:any): Observable<any> {
    return this.http.get(this.baseUrl+serviceUrl+'/'+ id);
  }

  createDynaFormElementsLov(data:any): Observable<any> {
    return this.http.post(this.baseUrl+serviceUrl, data);
  }

  updateDynaFormElementsLov(data:any): Observable<any> {
    return this.http.put(this.baseUrl+serviceUrl, data);
  }
}
