import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class PortalService {
    //private serviceId: string;
    //private portal: boolean;
    constructor() {}

    isPortal() {
        return !!Boolean(localStorage.getItem("esam_portal_isportal"));
    }

    getServiceId() {
        return localStorage.getItem("esam_portal_serviceid")
    }
    
    setPortal(portal: Boolean) {
        localStorage.setItem("esam_portal_isportal", String(portal))
    }

    setServiceId(serviceId: string) {
        localStorage.setItem("esam_portal_serviceid", serviceId)
    }
}
