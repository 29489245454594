import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExternalAccessDetailsService } from '../demo/service/externalaccessdetails.service';
import { GenerateReportService } from '../demo/service/generateReport.service';
import { ServiceDetailsService } from '../demo/service/serviceDetailsService';


@Component({
  selector: 'app-extwelcome',
  styleUrls: ['./app.extwelcome.component.scss'],
  templateUrl: './app.extwelcome.component.html',
  providers: [MessageService]
})
export class AppExtWelcomeComponent implements OnInit  {
  sacheck:any;
  serviceId:any;
  visitId:any;
  password:any;
  sacheckFlag:boolean = false;
  serviceType:any;
  constructor(private externalaccessservice : ExternalAccessDetailsService,private router: Router,
    private messageService: MessageService, private servicedetailsSrvc: ServiceDetailsService
    ,private generateReportService: GenerateReportService) { 
  
  }
    ngOnInit(): void {
      this.serviceId=localStorage.getItem('serviceId');
      this.visitId=localStorage.getItem('visitId');
      this.password=localStorage.getItem('password');

      console.log("this.serviceId"+this.serviceId)
      console.log("this.visitId"+this.visitId)
      console.log("this.password"+this.password)

          //get service type
          this.servicedetailsSrvc.getServiceDetails( this.serviceId).subscribe(data => {
            if (data != null) {
                this.serviceType = data.serviceType;
                

             

            }

        }, err => {
            console.log("serviceType not found");
        });

      this.externalaccessservice.getServiceDetails(this.serviceId,this.visitId,this.password).subscribe(data=>{
        if(data!=null)
        {
          this.sacheck = data.optFlag;
       
          if(this.sacheck && this.sacheck!=null)
          {
            this.sacheckFlag=true;
          }
         
         
        }
        },err=>{
          console.log("error externalaccessservice.getServiceDetails")
        });

        
    }

 
    save()
    {


     
      // update the table for external access
     
      if(this.sacheck)
      {

    
      this.externalaccessservice.getServiceDetails(this.serviceId,this.visitId,this.password).subscribe(data=>{
       
        if(data!=null && data.optFlag!==this.sacheck)
      {

            
        data.optFlag=this.sacheck;
        data.optinTimestamp=new Date();
        data.updatedDate = new Date();
        if(localStorage.getItem('uid')!=null &&   localStorage.getItem('uid')!=undefined)
        {
          data.updatedBy= localStorage.getItem('uid');
        }
        else{
          data.updatedBy= 'System';

        }
        
        if(this.sacheck && this.sacheck!=null)
          {
            this.sacheckFlag=true;
          }
        this.externalaccessservice.updateAccessDetails(data).subscribe(res=>{
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save successful' });
          console.log("response update");

          //sendemail to Assessor
          this.generateReportService.sendACOemail(this.visitId,this.serviceId,data.optFlag).subscribe(res2=>{
            console.log("email sent to assessor ")
            //this.messageService.add({ severity: 'success', summary: 'success', detail: 'email sent to assessor' });
          },err=>{
            console.log("error while email to assessor ");
          })

         
        },err=>{
          console.log("error while externalaccessservice.updateAccessDetails ");
        });
      }
      
      },err=>{
        console.log("error while externalaccessservice.getServiceDetails ")
      });
    

        
    }
  }
  erroronpage=false;
  nextPage()
  {
    if(this.sacheck==undefined || this.sacheck==null || this.sacheck=='')
    {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Selection has not been made' });
      this.erroronpage=true;
      return;
    }
    this.save();
    this.router.navigate(['main/externalaccess/steps']);
  }
  mainPage()
  {
    this.save();
    this.router.navigate(['main/externalaccess']);
  }
}
