import { Component,OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ExternalAccessDetailsService } from '../demo/service/externalaccessdetails.service';


@Component({
  selector: 'app-confirmationmsg',
  styleUrls: ['./app.confirmationmsg.component.scss'],
  templateUrl: './app.confirmationmsg.component.html',
  providers: [MessageService]
})
export class ConfirmationMessageComponent implements OnInit  {
  sacheck:any;
  serviceId:any;
  visitId:any;
  password:any;
  sacheckFlag:boolean = false;
  portalFlag='exist';
  constructor(private externalaccessservice : ExternalAccessDetailsService,private router: Router,private messageService: MessageService) { 
  
  }
    ngOnInit(): void {
      //localStorage.clear();
      //get commenecement letter flag
      this.serviceId = localStorage.getItem('serviceId');
      this.visitId = localStorage.getItem("visitId");

      console.log(" this.serviceId "+ this.serviceId )
      console.log(" this.visitId "+ this.visitId )


       this.externalaccessservice.getForServiceASR(this.serviceId, this.visitId).subscribe(ext=>{
        console.log(" this.portalFlag"+ ext)
   
        if(ext!=null)
        {
            this.portalFlag = ext.commencementLetterFlag;
            console.log(" this.portalFlag"+ this.portalFlag)

            localStorage.removeItem('serviceId');
            localStorage.removeItem('visitId');
            localStorage.removeItem('password');
        }
    })

      window.onpopstate = function (e) { window.history.forward(); }
     
        
    }
    
}
