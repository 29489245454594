import { Component } from '@angular/core';
import { AppConfigService } from '../demo/service/AppConfigService';

@Component({
  selector: 'app-portalaccessdenied',
  templateUrl: './app.portalaccessdenied.component.html',
})
export class PortalAccessdeniedComponent {

  portalUrl="";
  constructor(private config: AppConfigService)
  {
    
  }
  gotoportalpage()
  {
    this.portalUrl= this.config.getConfig().portalUrl;    
    window.open(this.portalUrl, "_self");

  }

}
