import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { IPortalMessage } from '../domain/portalmessage';
import { Observable } from 'rxjs';
import { AppConfigService } from './AppConfigService';

const baseUrl =  '/api/portal-message';

@Injectable({
  providedIn: 'root'
})
export class PortalMessageService {

  serviceUrl:any;
  constructor(private http: HttpClient,private config: AppConfigService) {

    this.serviceUrl= this.config.getConfig().baseUrl;
  }


  get(id:any): Observable<any> {
    return this.http.get(this.serviceUrl+baseUrl+'/'+ id);
  }

  create(data:any): Observable<any> {
    return this.http.post(this.serviceUrl+baseUrl, data);
  }

  update(data:any): Observable<any> {
    return this.http.put(this.serviceUrl+baseUrl, data);
  }
  getAll(): Observable<any> {
    return this.http.get<IPortalMessage[]>( this.serviceUrl+baseUrl);
  }

}
