import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
//import {DashboardComponent} from './demo/view/dashboard.component';
/*import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';*/
//import {PreVisitDetailsComponent} from './demo/view/previsitdetails.component';
//import {PostVisitDetailsComponent} from './demo/view/postvisitdetails.component';
//import {PeerReviewComponent} from './demo/view/peerreview.component';
//import {VisitListComponent} from './demo/view/visitlist.component';
//import {FinishedVisitListComponent} from './demo/view/finishedvisitlist.component';
//import {EmailtemplateComponent} from './demo/view/emailtemplate.component';
//import {FDCPreVisitDetailsComponent} from './demo/view/fdcprevisitdetails.component';
//import {FDCPostVisitDetailsComponent} from './demo/view/fdcpostvisitdetails.component';
//import {FDCVisitlistComponent} from './demo/view/fdcvisitlist.component';
//import {SurveyFormComponent} from './demo/components/survey/surveyform.component';

//import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
//import {FileDemoComponent} from './demo/view/filedemo.component';
//import {DocumentationComponent} from './demo/view/documentation.component';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import { AppDocumentComponent } from './pages/app.doc.component';
import {AppExternalAccessComponent} from './pages/app.externalaccess.component';
import {HomeComponent} from './demo/view/home.component';
//import { AppSurveyComponent } from './pages/app.survey.component';
//import { AppSurveyFormComponent } from './pages/app.surveyform.component';

//import { AppDraftResponseComponent } from './pages/app.draftresponse.component';
import {AppExtWelcomeComponent} from './pages/app.extwelcome.component';
/*import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {WidgetsComponent} from './utilities/widgets.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppHelpComponent} from './pages/app.help.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';*/
import { OAuth2ComponentComponent } from './demo/security/oauth2-component/oauth2-component.component';
import { DashboardComponent} from './demo/components/dashboard/dashboard.component';
import { ConfirmationMessageComponent } from './pages/app.confirmationmsg.component';
import { AppDraftResponseComponent } from './pages/app.draftresponse.component';
import { FBConfirmationMessageComponent } from './pages/app.fbconfirmationmsg.component';
import { FBNoSelectionMessageComponent } from './pages/app.fbnoselectionmsg.component';
import { FBExitMessageComponent } from './pages/app.fbexitmsg.component';
import { AppSurveyFormComponent } from './pages/app.surveyform.component';
import { AppSurveyComponent } from './pages/app.survey.component';
import { SurveyConfirmationMessageComponent } from './pages/app.surveyconfirmationmsg.component';
import { RedirectToPortalGuard } from './guards/redirecttoportal.guard';
import { LogoutGuard } from './guards/logout.guard';
import { AuthGuardService } from './guards/auth-guard.service';
import { PortalAccessdeniedComponent } from './pages/app.portalaccessdenied.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            { path: '', redirectTo: 'login',   pathMatch: 'full'},            
  {path: 'oauth2/redirect', component: OAuth2ComponentComponent},
  {path: 'oauth2/logout', component: OAuth2ComponentComponent, canActivate: [LogoutGuard]},
            {
                path: 'main', component: AppMainComponent,
                children: [
                    {path: 'maindash', component: HomeComponent},
                   // {path: 'maindash', component: HomeComponent},
                //   {path: 'maindash', component: VisitListComponent},
                  // {path: 'finished', component: FinishedVisitListComponent},
                    { 
                        path: 'qiip',
                        children: [
                            {path: 'dashboard', loadChildren: () => import('./demo/components/dashboard/dashboard.component.module').then(m => m.DashboardComponentModule), canLoad: [AuthGuardService]},
                            {path: 'usefullinks', loadChildren: () => import('./demo/components/usefullinks/usefullinks.component.module').then(m => m.UsefullinksComponentModule), canLoad: [AuthGuardService]},
                            {path: 'documents', loadChildren: () => import('./demo/components/portaldocuments/portaldoc.component.module').then(m => m.PortaldocComponentModule), canLoad: [AuthGuardService]},
                            {path: 'portalsa', loadChildren: () => import('./demo/components/portalselfassess/portalsa.component.module').then(m => m.PortalSaComponentModule), canLoad: [AuthGuardService]},
                            {path: 'portalip', loadChildren: () => import('./demo/components/portalimproveplan/portalip.component.module').then(m => m.ImprovePlanComponentModule),canLoad: [AuthGuardService]},
                            {path: 'portallaw', loadChildren: () => import('./demo/components/lawandregulation/portallaw.component.module').then(m => m.PortalLawComponentModule), canLoad: [AuthGuardService]},
                        ],
                        
                    },                 
                   // {path: 'logon', component: DashboardComponent},
                    {path: 'externalaccess/steps', loadChildren: () => import('./demo/components/servicecontext/stepsdemo.module').then(m => m.StepsDemoModule)},
                    {path: 'externalaccess/selfassessment', loadChildren: () => import('./demo/components/selfassessment/stepsdemo.module').then(m => m.StepsDemoModule)},
                    {path: 'externalaccess/responsetodraft', loadChildren: () => import('./demo/components/responsetodraft/steps1demo.module').then(m => m.Steps1DemoModule)},
                  /*  {path: 'uikit/formlayout', component: FormLayoutDemoComponent},
                    {path: 'uikit/input', component: InputDemoComponent},
                    {path: 'uikit/button', component: ButtonDemoComponent},
                    {path: 'uikit/table', component: TableDemoComponent},
                    {path: 'uikit/list', component: ListDemoComponent},
                    {path: 'uikit/tree', component: TreeDemoComponent},
                    {path: 'uikit/panel', component: PanelsDemoComponent},
                    {path: 'uikit/overlay', component: OverlaysDemoComponent},
                    {path: 'uikit/media', component: MediaDemoComponent},
                    {path: 'uikit/menu', component: MenusDemoComponent},
                    {path: 'uikit/message', component: MessagesDemoComponent},
                    {path: 'uikit/misc', component: MiscDemoComponent},
                    {path: 'uikit/charts', component: ChartsDemoComponent},
                    {path: 'uikit/file', component: FileDemoComponent},
                    {path: 'utilities/display', component: DisplayComponent},
                    {path: 'utilities/elevation', component: ElevationComponent},
                    {path: 'utilities/flexbox', component: FlexboxComponent},
                    {path: 'utilities/grid', component: GridComponent},
                    {path: 'utilities/icons', component: IconsComponent},
                    {path: 'utilities/widgets', component: WidgetsComponent},
                    {path: 'utilities/spacing', component: SpacingComponent},
                    {path: 'utilities/typography', component: TypographyComponent},
                    {path: 'utilities/text', component: TextComponent},
                    {path: 'pages/crud', component: AppCrudComponent},
                    {path: 'pages/calendar', component: AppCalendarComponent},
                    {path: 'pages/invoice', component: AppInvoiceComponent},
                    {path: 'pages/help', component: AppHelpComponent},
                    {path: 'pages/empty', component: EmptyDemoComponent},*/
                  //  {path: 'admin/email', component: EmailtemplateComponent}, 
                  //  {path: 'pages/previsit', component: PreVisitDetailsComponent}, 
                   // {path: 'pages/postvisit', component: PostVisitDetailsComponent}, 
                  //  {path: 'pages/peerreview', component: PeerReviewComponent}, 
                  //  {path: 'pages/fdcprevisit', component: FDCPreVisitDetailsComponent}, 
                  //  {path: 'pages/fdcpostvisit', component: FDCPostVisitDetailsComponent}, 
                  //  {path: 'pages/fdcvisitlist', component: FDCVisitlistComponent},
                  //  {path: 'survey/surveyform', component: AppSurveyComponent}, 
                    {path: 'externalaccess/appsurveyform', component: AppSurveyFormComponent}, 
                     //   {path: 'pages/visitlist', component: DashboardComponent},                    
                 //   {path: 'documentation', component: DocumentationComponent},
                    {path: 'externalaccess', component: AppExternalAccessComponent, canActivate: [RedirectToPortalGuard]},
                    //{path: 'externalaccess', component: AppExternalAccessComponent},
                    {path: 'externalaccess/survey', component: AppSurveyComponent},
                   {path: 'externalaccess/feedback', component: AppDraftResponseComponent},                 
                    {path: 'externalaccess/welcome', component: AppExtWelcomeComponent},
                    {path: 'externalaccess/confirmmsg', component: ConfirmationMessageComponent}, 
                    {path: 'externalaccess/fbconfirmmsg', component: FBConfirmationMessageComponent}, 
                    {path: 'externalaccess/surveyconfirmmsg', component: SurveyConfirmationMessageComponent}, 
                    {path: 'externalaccess/fbnoselection', component: FBNoSelectionMessageComponent}, 
                    {path: 'externalaccess/fbexitpage', component: FBExitMessageComponent, canActivate: [RedirectToPortalGuard]}, 
                ]
            },
            { path: 'documentation', component: AppDocumentComponent },
            {path: 'error', component: AppErrorComponent},
            {path: 'assets/config', component: AppErrorComponent},
            {path: 'access', component: AppAccessdeniedComponent},
            {path: 'portalerror', component: PortalAccessdeniedComponent},
            {path: 'notfound', component: AppNotfoundComponent},
            {path: 'login', component: AppLoginComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
