import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { SSOLogonService } from "../demo/service/ssologon.service";
import { PortalService } from "../services/portal.service";

@Injectable({
    providedIn: "root",
})
export class LogoutGuard implements CanActivate {
    constructor(private ssoLogonService: SSOLogonService, private portalService: PortalService) {

    }
    canActivate(
        _route: ActivatedRouteSnapshot,
        _state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.portalService.setPortal(true)
        this.ssoLogonService.getLogout()

        return true;
    }
}
