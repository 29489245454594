
import { NONE_TYPE } from '@angular/compiler';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { ServiceDetailsService } from '../../app/demo/service/serviceDetailsService';
import { IDynaFormValues } from '../demo/domain/dyna-form-values';
import { DynaFormElementsLovService } from '../demo/service/dyna-form-elements-lov.service';
import { DynaFormElementsService } from '../demo/service/dyna-form-elements.service';
import { DynaFormValuesService } from '../demo/service/dyna-form-values.service';
import { DynaSurveyService } from '../demo/service/dyna-survey.service';
import { GenerateReportService } from '../demo/service/generateReport.service';



@Component({
    selector: 'app-surveyform',
    templateUrl: './app.surveyform.component.html',
    styleUrls: ['app.surveyform.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    providers: [MessageService]
})
export class AppSurveyFormComponent implements OnInit {
    city: string;
    inputServiceId: string = '';
    inputAsrId: string = '';
    surveyId: string = '';
    serviceName: string = '';
    counter: number = 0;
    elementsList: any[] = [];
    elementValuesList:any[]=[];
    elementsLovList: any[] = [];
    submitted = false;
    buttondis:boolean=false;
    constructor(public messageService: MessageService, private servicedetailsSrvc: ServiceDetailsService,  private router: Router,
         private dynaFormElementsSrvc: DynaFormElementsService, private dynaFormValuesSrvc: DynaFormValuesService,
         private confirmationService: ConfirmationService,private generateReport: GenerateReportService,
         private dynaSurveyService: DynaSurveyService,  private dynaFormEleLovSrvc: DynaFormElementsLovService
         ) { }
     ngOnInit() {

        this.inputServiceId = localStorage.getItem('serviceId');
        this.inputAsrId = localStorage.getItem('visitId');
        this.counter = 0;
        this.submitted = false;
        this.surveyId = localStorage.getItem("surveyId");

      


        this.servicedetailsSrvc.getServiceDetails(this.inputServiceId).subscribe(data => {
             this.serviceName=data.serviceName;
         });
        this.dynaFormElementsSrvc.getAllDynaFormElements().subscribe(result => {
            this.elementsList = result;
            this.elementsList = this.elementsList.sort((a, b) => (a.elementId > b.elementId) ? 1 : ((b.elementId > a.elementId) ? -1 : 0));
            this.elementsList.forEach(el => {
                el.formvalue={};
                if (el.lableName.indexOf('If other, please specify')==-1) {
                    this.counter = this.counter + 1;
                    el.counter = this.counter;
                }
            })
        }, err => {
            console.log(err);
        })

        this.dynaFormEleLovSrvc.getAllDynaFormElementsLov().subscribe(result => {
            this.elementsLovList = result;


        }, err => {
            console.log(err);
        })
   

        this.dynaFormValuesSrvc.getDynaFormValuesForsurveyId(this.surveyId).subscribe(result => {
         
           this.elementValuesList=result;

          

            this.elementValuesList.forEach(e=> {
                
                this.elementsList.forEach(el=> {
                
                    if(e.elementId===el.elementId)
                    {
                        el.formvalue=e;
                        
                     
            
                    }
                })
            })



        }, err => {
            console.log(err);
        })

    }


    getLovforElementId(elementId: any) {
        // alert("elementId"+elementId)
        let arr = this.elementsLovList.filter(el => el.elementId === elementId);
        return arr.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : ((b.displayOrder > a.displayOrder) ? -1 : 0));


        //  let arr= this.elementsLovList.map(el=>el.elementId==elementId);
        // alert(arr.length);
    }
    dynaFormValue:IDynaFormValues={};
    async save()
    {
   this.buttondis =true;
   //   await  this.validateData();
   let validateflag = true;
   //let validatebuff = "";


     for await (const el of this.elementsList) {

        if(el.lableName.indexOf('If other, please specify')<= -1 && ( el.formvalue.elementValue==undefined || el.formvalue.elementValue==null || el.formvalue.elementValue.trim()==''))
        {
           // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Validation Error'});
            console.log('validation failed')
            el.flag=false; 
            validateflag = false;
            this.buttondis =false;
           // validatebuff = validatebuff +  el.lableName + 'Validation Error: Value is required' + '<br/>' ;
           this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Please complete the required fields highlighted in red'});


          
            
        }

       
        
        
    }


   /* if(validateflag==false){

        this.confirmationService.confirm({
            message: validatebuff,
            header: 'Error',
            icon: 'pi pi-exclamation-triangle',
            rejectVisible: false,
            acceptLabel: "OK",
            reject: () => {
                this.confirmationService.close();
            },
          
            accept: async () => {
                this.confirmationService.close();
            }
        });

    }*/
    if(validateflag==true){
       let counter=0;

       let mypromise = new Promise((resolve,reject) => {
        
        this.elementsList.forEach((el,index) =>{
          
       
            if(el.formvalue!=null && el.formvalue!=undefined && el.formvalue.elementValueId!=null && el.formvalue.elementValueId!=undefined && el.formvalue.elementValue!=null && el.formvalue.elementValue!=undefined && el.formvalue.elementValue.trim()!='')
            {
                let dynaformobj = {'elementValueId':el.formvalue.elementValueId,'elementId':el.elementId, 'surveyId': this.surveyId, formId:'2', 'elementValue': el.formvalue.elementValue, 'updatedBy': 'ServiceProvider User', 'createdDate': new Date()}
               
                this.dynaFormValuesSrvc.updateDynaFormValues(dynaformobj).subscribe(res=>{
                  
                    console.log("data saved");
                    
    
                },err=>{
                    console.log("data save error");
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Save failed' });
                    return;
                })
            }
            else if(el.formvalue!=null && el.formvalue!=undefined && el.formvalue.elementValue!=null && el.formvalue.elementValue!=undefined && el.formvalue.elementValue.trim()!='' &&(el.formvalue.elementValueId==null || el.formvalue.elementValueId==undefined)){
               
                let dynaformobj = {'elementId':el.elementId, 'surveyId': this.surveyId, formId:'2','elementValue': el.formvalue.elementValue, 'createdBy': 'ServiceProvider User', 'createdDate': new Date()}
               
                console.log("dynaformobj"+JSON.stringify(dynaformobj))
                this.dynaFormValuesSrvc.createDynaFormValues(dynaformobj).subscribe(res=>{
                  
                
                    console.log("data saved");
                    counter++;
    
                },err=>{
                    console.log("data save error");
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Save failed' });
                    return;
                })
            }
          //  alert(this.elementsList.length + "index"+index)
            if(index === this.elementsList.length -1 )
            {
               
                resolve(null);
            }
               
           
    
    
                
                
            });

       } )

      

       mypromise.then(() => {

      
        this.dynaSurveyService.getDynaSurveyForvisitId(this.inputAsrId, this.inputServiceId).subscribe(res1=>{
           
            if(res1)
            {
                res1.status="Submitted";
                res1.submittedBy="System";
                res1.submittedDate=new Date();

                this.dynaSurveyService.updateDynaSurvey(res1).subscribe(res2=>{
                    console.log("Dyna survey status updated");
                    this.generateReport.sendSurveySubmissionEmail(this.inputAsrId,this.inputServiceId).subscribe(subres=>{
                        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Email sent' });  
                    },err=>{
                        console.log("send survey email failed")
                    });
                    this.buttondis =false;
                   // this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Save successful' });
                    this.router.navigate(['main/externalaccess/surveyconfirmmsg']);
                },err=>{
                    this.buttondis =false;
                    console.log("Dyna survey status update failed");
                    this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Save failed' });
                    return;
                })    
            }

        })
       
      });

      
         
          
       
    }
      
            

    }
  
    validateData()
    {
        
       
        let validateflag = true;
        let validatebuff = "";
            for (const el of this.elementsList) {

              
                if(el.lableName.indexOf('If other, please specify')<= -1  &&  el.lableName.indexOf('Do you have any comments')<= -1 && ( el.formvalue.elementValue==undefined || el.formvalue.elementValue==null || el.formvalue.elementValue.trim()==''))
                {
                   // this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Validation error' + el.lableName});
                    console.log('validation failed')
                    el.flag=false; 
                    validateflag = false;
                    validatebuff = validatebuff +  el.lableName + 'Validation Error: Value is required' + '<br/>' ;


                  
                    
                }
                
                
            }

          
            if(validateflag==false){

                this.confirmationService.confirm({
                    message: validatebuff,
                    header: 'Error',
                    icon: 'pi pi-exclamation-triangle',
                    rejectVisible: false,
                    acceptLabel: "OK",
                    reject: () => {
                        this.confirmationService.close();
                    },
                  
                    accept: async () => {
                        this.confirmationService.close();
                    }
                });

            }
           
           


        
    }

}


