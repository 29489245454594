import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppMainComponent } from './app.main.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[];

    constructor(public app: AppMainComponent,
        public router: Router) { }

    ngOnInit() {
        this.model = [
            { label: 'Dashboard', icon: 'pi pi-fw pi-home', routerLink: ['/main/qiip/dashboard']},
            {                
                label: 'Documents', icon: 'pi pi-fw pi-book', command: () => {this.router.navigate(['/main/qiip/documents'])},
            
            },
            {                
                label: 'Useful Links', icon: 'pi pi-fw pi-list',
                items: [
                    {style:"white-space: nowrap;",  label: 'National Quality Framework', icon: 'pi pi-fw pi-external-link', target: '_blank', url: 'https://www.acecqa.gov.au/national-quality-framework' },
                    { label: 'Self Assessment for Quality Improvement', icon: 'pi pi-fw pi-external-link', target: '_blank', url: 'https://education.nsw.gov.au/early-childhood-education/regulation-and-compliance/assessment-and-rating-process/self-assessment'},
                    { label: 'ACECQA resources', icon: 'pi pi-fw pi-external-link', target: '_blank', url: 'https://www.acecqa.gov.au/resources' }
                ]
            },
            {    
                            
                label: 'Help', icon: 'pi pi-fw pi-question-circle', routerLink: ['/documentation']
            
            }
          
            /*{
                label: 'Administration', icon: 'pi pi-fw pi-briefcase', routerLink: ['admin'],
                items: [
                    // {label: 'Crud', icon: 'pi pi-fw pi-pencil', routerLink: ['pages/crud']},
                    { label: 'Email Template', icon: 'pi pi-fw pi-envelope', routerLink: ['admin/emailtemplates'] },
                    { label: 'Manage User Details', icon: 'pi pi-fw pi-user-edit', routerLink: ['admin/userdetails'] },
                    { label: 'Service & Provider Details', icon: 'pi pi-fw pi-user-edit', routerLink: ['admin/servicedetail'] },
                    { label: 'Management Report', icon: 'pi pi-fw pi-book', routerLink: ['admin/mgmtreport'] },
                    { label: 'Service Context Expiry Date Maintenance', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['admin/servicecontext'] },
                    { label: 'Service Feedback Expiry Date Maintenance', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['admin/servicefeedback'] },
                    { label: 'Survey Expiry Date Maintenance', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['admin/survey'] },
                    { label: 'TRIM Operation', icon: 'pi pi-fw pi-folder', routerLink: ['admin/trim'] },
                    { label: 'QIP and SCD Notification Reminder Operation', icon: 'pi pi-fw pi-check-circle', routerLink: ['admin/qipreminder'] },
                    { label: 'Survey Details', icon: 'pi pi-fw pi-file', routerLink: ['admin/surveyform'] },
                    { label: 'WWCC Reports', icon: 'pi pi-fw pi-book', routerLink: ['admin/wwcc'] }
                ]
            },*/
            
        ];
    }

    onMenuClick() {
        this.app.menuClick = true;
    }
}
