import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpHeaders, HttpParams, HttpClient} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Location } from '@angular/common';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  ssourl:any="";
  uiurl:any="";
  baseUrl:any="";
  trimurl:any="";
  clientId:any="";
  constructor( @Inject(HttpClient) private http:HttpClient, private location: Location) {

    
  }
   intercept(req: HttpRequest<any>, next: HttpHandler):   Observable<HttpEvent<any>> {
    
  
    
    this.baseUrl = localStorage.getItem('baseUrl');
    this.clientId =localStorage.getItem('clientId');
    this.trimurl = localStorage.getItem('trimurl');
    this.ssourl =localStorage.getItem('ssourl');
    this.uiurl =localStorage.getItem('uiurl');
  //  var baseUrl = localStorage.getItem('baseUrl');
     var isbackend = false;
   //  var trimurl = localStorage.getItem('trimurl');
     var istrim = false;
 
  
     if(this.baseUrl!==null)
     {
      isbackend = req.url.indexOf(this.baseUrl)!==-1?true:false;

     }
   
      
      istrim = req.url.indexOf("trim")!==-1?true:false;
   
    
   
   // alert("router url"+this.location.path());
     let isportal = localStorage.getItem("isportal");
    if(this.location.path().includes("externalaccess") && (isportal==null || isportal==undefined || isportal!="true"))
    {
      let serviceId = localStorage.getItem("serviceId");
      let visitId = localStorage.getItem("visitId");
      let password = localStorage.getItem("password");
    //  console.log("serviceId"+serviceId)
  //    console.log("visitId"+visitId)
  //    console.log("password"+password)
      console.log("istrim"+istrim)
     
    

     if(req!==null  && (isbackend!=null || istrim) && serviceId!=null && visitId!=null && password!=null)
      {
       // console.log("this is inetrcept servId =" + serviceId);

       if(istrim)
       {
            req = req.clone({
              headers: req.headers.set("isscsaform", 'true')
          });
        }
        req = req.clone({
          headers: req.headers.set("serviceId", ''+serviceId).set("visitId", ''+visitId).set("password",password).set("externalaccess","true")
          
        });
     
      }
      return next.handle(req);
    }

   // const token = localStorage.getItem('access_token');
  
    // var tokenval = localStorage.getItem("access_token");
    var tokenval = localStorage.getItem("access_token");
    //console.log("id_token"+tokenval)


    if (req!==null &&  tokenval!==null && (isbackend || istrim)) {


      req = req.clone({
        headers: req.headers.set("ssotoken", ''+tokenval).set("appname","esamext").set("isportal","true")
        
      });
 
     
     
   

    }

  

    return next.handle(req);
 
  }

  erroHandler(error: HttpErrorResponse) {
    this.ssourl =localStorage.getItem('ssourl');
    this.uiurl =localStorage.getItem('uiurl');
   
    console.log("error"+JSON.stringify(error));
   
    if(error!==null && error.error.status===400)
    {
      window.location.href=this.ssourl+'/logout?goto='+this.uiurl+'/oauth2/logout';
     
    }
  
    return throwError(error.message || 'server Error');
  }


  getOAuth2AccessToken (): Observable<JSON> {
    this.ssourl =localStorage.getItem('ssourl');
    // tslint:disable-next-line:max-line-length
    const refresh_token = localStorage.getItem('refresh_token');
    //const ssosecret = localStorage.getItem("ssosecret");
    const verifier = localStorage.getItem('verifier') 
    const clientid = localStorage.getItem('clientId') 
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json',
        'Cache-Control': 'no-cache, no-store, max-age=0, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0',
        'X-Content-Type-Options': 'nosniff',
       'Strict-Transport-Security': 'max-age=31536000 ; includeSubDomains',
        'X-Frame-Options': 'DENY',
        'X-XSS-Protection': '1; mode=block'
      })
    };
    // const responseType = { response: new } responseType = 'json';
    const body = new HttpParams()
      .set('grant_type', 'refresh_token')
      .set('client_id', clientid)
      .set('refresh_token', ''+refresh_token)
     // .set('client_secret', '0Zs5Eldbq0x7kCVVrCMsAj0ewMYqPIa0')
     .set('code_verifier', ''+verifier)
    // .set('client_secret', ''+ssosecret)

   // console.log("refresh body"+JSON.stringify(body))
  //  return this.http.post<any>(this.ssourl+'/oauth2/access_token', body.toString(), httpOptions)
  return this.http.post<any>(this.ssourl+'/token', body.toString(), httpOptions) 
  // .pipe(map(result => result.json()))
      ;

    
  }
 
}